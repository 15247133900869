.footer {
}
.fnav {
	padding: 60px 0 40px;
	@include sp() {
		padding: 40px 0;
	}
	ul {
		display: flex;
		justify-content: center;
		background: url(../img/common/fnav_left.png) left center no-repeat, url(../img/common/fnav_right.png) right center no-repeat;
		max-width: 930px;
		margin: 0 auto;
		@include sp() {
			background: none;
		}
		li {
			@include sp() {
				width: 25%;
			}
			+ li {
				margin-left: 70px;
				@include sp() {
					margin-left: 0;
				}
			}
			a {
				display: block;
				text-align: center;
				@include sp() {
					padding: 0 5px;
				}
				img {
					width: 57px;
				}
			}
		}
	}
}
.ftr-cv {
	background: url(../img/common/ftr_bg.png) top 15px center no-repeat;
	padding: 100px 0 20px;
	text-align: center;
	@include sp() {
		padding: 60px 0 20px;
	}
	&__logo {
		margin-bottom: 45px;
	}
	&__addr {
		margin-bottom: 10px;
	}
	&__tel {
		display: inline-block;
		margin-bottom: 10px;
	}
	&__time {
		letter-spacing: 0;
		margin-bottom: 60px;
	}
	&__copy {
		font-size: 12px;
	}
}
