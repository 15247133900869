@include sp() {
	.pc {
		display: none !important;
	}
}
@include sp-up() {
	.sp {
		display: none !important;
	}
}
.c- {
	&bg {
		background: url(../img/common/bg.jpg);
	}
	&mv {
		padding-top: 40px;
		padding-left: 355px;
		position: relative;
		@include sp() {
			margin: 20px 0 0;
		}
		&__ttl {
			position: absolute;
			bottom: 45px;
			left: 459px;
			@include sp() {
				left: 25px;
				top: 0;
				bottom: 0;
				margin: auto 0;
			}
		}
	}
	&hover {
		&__opacity {
			a {
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
	&inner {
		max-width: 1160px;
		margin: 0 auto;
		@include sp() {
			padding: 0 16px;
		}
	}
	&intro {
		padding: 80px 0 40px;
		text-align: center;
		@include sp() {
			padding: 40px 0;
		}
		&__title {
			font-size: 30px;
			margin-bottom: 30px;
			font-weight: 500;
			@include sp() {

			}
		}
		&__text {
		}
	}
}
