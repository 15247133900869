#staff {
	.sec01 {
		padding: 90px 0 70px;
		@include sp() {
			padding: 40px 0;
		}
		&__title {
			text-align: center;
			margin-bottom: 50px;
		}
	}
	.sec01-staff {
		display: flex;
		justify-content: space-between;
		@include sp() {
			display: block;
			> div {
				width: 100% !important;
			}
		}
		&__image {
			width: 379px;
			@include sp() {
				margin-bottom: 20px;
				text-align: center;
			}
		}
		&__textarea {
			width: 675px;
			.name {
				font-size: 23px;
				display: flex;
				align-items: center;
				font-weight: 500;
				margin-bottom: 40px;
				@include sp() {
					display: block;
					margin-bottom: 10px;
				}
				span {
					font-size: 14px;
					color: $blue;
					margin-left: 30px;
					@include sp() {
						display: block;
						margin: 0;
					}
				}
			}
			.text {
				margin-bottom: 40px;
				@include sp() {
					margin-bottom: 20px;
				}
			}
		}
	}
	.sec02 {
		padding: 70px 0 125px;
		@include sp() {
			padding: 40px 0;
		}
	}
	.sec02-staff {
		display: flex;
		+ .sec02-staff {
			margin-top: 70px;
		}
		@include sp() {
			display: block;
			> div {
				width: 100% !important;
			}
		}
		&__image {
			width: 379px;
			@include sp() {
				margin-bottom: 20px;
				text-align: center;
			}
		}
		&__image {
			width: 280px;
			margin-right: 95px;
			@include sp() {
				margin-right: 0;
			}
		}
		&__textarea {
			width: 675px;
			.name {
				font-size: 23px;
				display: flex;
				align-items: center;
				font-weight: 500;
				margin-bottom: 40px;
				@include sp() {
					display: block;
					margin-bottom: 10px;
				}
				span {
					font-size: 14px;
					color: $blue;
					margin-left: 30px;
					@include sp() {
						display: block;
						margin: 0 0;
					}
				}
			}
			.text {
				margin-bottom: 40px;
				@include sp() {
					margin-bottom: 20px;
				}
			}
		}
	}
	.history {
		padding: 20px 10px;
		border-top: 1px solid #c9caca;
		border-bottom: 1px solid #c9caca;
		&__title {
			font-size: 18px;
			color: $blue;
			padding: 5px 10px;
			width: 80px;
			@include sp() {
				margin-bottom: 5px;
			}
		}
		table {
			width: 100%;
			@include sp() {
				display: block;
				tbody,tr,td {
					display: block;
					width: 100% !important;
				}
			}
			tr {
				+ tr {
					@include sp() {
						margin-top: 5px;
					}
				}
				td {
					vertical-align: top;
					padding: 5px 0;
					line-height: 1.5;
					@include sp() {
						padding: 0;
						+ td {
							margin-top: 2px;
						}
					}
				}
			}
		}
	}
	.sec03 {
		background: $blue;
		padding: 55px 0 90px;
		@include sp() {
			padding: 40px 0;
		}
		&__title {
			text-align: center;
			color: white;
			font-size: 30px;
			margin-bottom: 50px;
			font-weight: 500;
			@include sp() {
				margin-bottom: 30px;
			}
		}
	}
	.sec03-box {
		border-radius: 16px;
		padding: 75px 120px;
		background: url(../img/staff/img04.png) center right 120px no-repeat #fff;
		max-width: 994px;
		margin: 0 auto;
		@include sp() {
			padding: 20px 20px 180px;
		background: url(../img/staff/img04.png) center bottom no-repeat #fff;
		}
		&__text {
			margin-bottom: 30px;
			@include sp() {
				margin-bottom: 10px;
			}
		}
		&__tel {
			display: inline-block;
			width: 525px;
			text-align: center;
			border: 1px solid #b4b3b3;
			padding: 20px 0;
			@include sp() {
				width: 100%;
			}
		}
	}
}
