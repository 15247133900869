* {
	box-sizing: border-box;
}

html {
	font-size: 10px;
}

body {
	font-family: "Helvetica Neue" , Helvetica , Arial , Verdana , Roboto , "游ゴシック" , "Yu Gothic" , "游ゴシック体" , "YuGothic" , "ヒラギノ角ゴ Pro W3" , "Hiragino Kaku Gothic Pro" , "Meiryo UI" , "メイリオ" , Meiryo , "ＭＳ Ｐゴシック" , "MS PGothic" , sans-serif;
	line-height: 1;
	font-size: 14px;
	font-weight: 500;
	color: #333;
	min-width: 1160px;
	@include sp() {
		font-size: 14px;
		min-width: auto;
	}
	@include ie11only {
		font-family: "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	}
}
.serif {
	font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
}
img {
	max-width: 100%;
	vertical-align: bottom;
	height: auto;
}

a,button {
	transition: 0.2s ease;
	word-break: break-word;
	display: inline-block;
	color: #333;
	&:hover {
		text-decoration: none;
		opacity: .8;
	}
	&:visited {
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.5;
}
p {
	line-height: 1.8;
	letter-spacing: 0.05em;
	word-break: break-word;
}
