#top {
	.slider {
		margin-bottom: 0 !important;
		&__line {
			position: absolute;
			right: -70px;
			top: 180px;
			@include sp() {
				position: static;
				text-align: center;
				margin: 70px auto 0;
				display: block;
				width: 52px;
			}
		}
	}
	.c-mv {
		position: relative;
		div {
			line-height: 1;
			vertical-align: bottom;
		}
		&.slick-slider {
		}
		.slick-arrow {
			width: 45px;
			height: 45px;
			position: absolute;
			top: 220px;
			right: -70px;
			cursor: pointer;
			z-index: 10;
			@include sp() {
				bottom: 0;
				right: 5px;
				top: 0 !important;
				margin: auto 0;
				width: 30px;
				height: 30px;
			}
			&.prev {
				top: 290px;
				transform: rotate(180deg);
				@include sp() {
					left: 5px;
					right: auto;
				}
			}
			&:hover {
				circle {
					stroke: $blue;
				}
				path {
					fill: $blue;
				}
			}
		}
		.slick-dots {
			bottom: 0;
			right: -54px;
			left: auto;
			width: auto;
			@include sp() {
				bottom: -20px;
				left: 0;
				right: 0;
				display: flex;
				justify-content: center;
			}
			li {
				display: block;
				width: auto;
				height: auto;
				margin: 0;
				@include sp() {
				}
				&.slick-active {
					button {
						background: $blue;
					}
				}
				+ li {
					margin-top: 25px;
					@include sp() {
						margin-top: 0;
						margin-left: 10px;
					}
				}
				button {
					width: 12px;
					height: 12px;
					padding: 0;
					background: #dcdddd;
					border-radius: 50%;
					&:hover {
						background: $blue;
					}
					&:before {
						content: none;
					}
				}
			}
		}
		.slick-list {
			img {
				width: 100%;
			}
		}
	}
	.sec01 {
		padding: 50px 0;
		@include sp() {
			padding: 40px 0;
		}
		&__title {
			text-align: center;
			margin-bottom: 50px;
		}
	}
	.sec01-icon {
		display: flex;
		justify-content: center;
		background: url(../img/top/tree_left.png) left bottom no-repeat, url(../img/top/tree_right.png) right bottom no-repeat;
		max-width: 1060px;
		margin: 0 auto;
		@include sp() {
			flex-wrap: wrap;
			margin-top: -20px;
			padding-bottom: 60px;
		}
		li {
			text-align: center;
			color: $blue;
			display: flex;
			flex-direction: column-reverse;
			align-items: center;
			font-size: 16px;
			margin: 0 15px;
			@include sp() {
				margin-top: 20px;
			}
			p {
				margin-bottom: 15px;
				@include sp() {
					margin-bottom: 5px;
				}
			}
			img {
				margin-top: auto;
				width: auto;
			}
		}
	}
	.sec02 {
		padding-bottom: 60px;
		&__title {
			text-align: center;
			margin-bottom: 30px;
		}
		&__text {
			text-align: center;
			margin-bottom: 50px;
		}
	}
	.sec02-flex {
		display: flex;
		justify-content: space-between;
		@include sp() {
			display: block;
		}
		> div {
			@include sp() {
				width: 100% !important;
			}
		}
		&__left {
			width: 580px;
			border-radius: 16px;
			overflow: hidden;
			@include sp() {
				margin-bottom: 20px;
			}
			iframe {
				width: 100%;
				height: 360px;
			}
		}
		&__right {
			width: 535px;
			display: flex;
			flex-direction: column;
			.image {
				text-align: center;
			}
		}
	}
	.sec02-access {
		border: 1px solid #595757;
		border-radius: 8px;
		margin-top: auto;
		font-size: 16px;
		padding: 20px 15px;
		@include sp() {
			margin-top: 20px;
		}
		> div {
			display: flex;
			align-items: center;
			@include sp() {
				flex-direction: column;
				justify-content: center;
				text-align: center;
				+ div {
					margin-top: 20px;
				}
			}
		}
		span {
			color: $blue;
			font-size: 14px;
			margin: 0 5px;
			@include sp() {
				display: block;
				text-align: center;
				margin: 0;
				transform: rotate(90deg);
			}
		}
		&__train {
			margin-bottom: 10px;
		}
		&__bus {
		}
		&__image {
			width: 69px;
			text-align: center;
			margin-right: 20px;
			@include sp() {
				margin: 0 0 10px;
			}
		}
	}
	.sec03 {
		padding-bottom: 80px;
		&__title {
			text-align: center;
			margin-bottom: 30px;
		}
		&__table {
			text-align: center;
			margin-bottom: 10px;
		}
		&__text {
			color: $blue;
			font-size: 16px;
			text-align: center;
			margin-bottom: 40px;
		}
	}
	.sec03-list {
		display: flex;
		align-items: center;
		justify-content: center;
		@include sp() {
			display: block;
			text-align: center;
		}
		li {
			display: flex;
			align-items: center;
			font-size: 20px;
			@include sp() {
				justify-content: center;
			}
			> img {
				margin-right: 15px;
			}
			+ li {
				margin-left: 70px;
				@include sp() {
					margin: 15px 0 0;
				}
			}
		}
	}
	.sec04 {
		padding-bottom: 160px;
		@include sp() {
			padding-bottom: 80px;
		}
		&__title {
			text-align: center;
			margin-bottom: 30px;
		}
		&__text {
			font-size: 16px;
			text-align: center;
			margin-bottom: 40px;
		}
		&__frame {
			text-align: center;
			iframe {
				width: 993px;
				height: 762px;
				@include sp() {
					width: 100%;
					height: 400px;
				}
			}
		}
	}
	.sec05 {
		position: relative;
		&__title {
			text-align: center;
			margin-bottom: 25px;
		}
		&__bg {
			position: absolute;
			width: 1060px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			transform: translateY(-100%);
			left: 0;
			right: 0;
			@include sp() {
				display: none;
			}
		}
	}
	.sec05-list {
		background: $blue;
		padding: 65px 0;
		display: flex;
		justify-content: center;
		@include sp() {
			padding: 40px 16px;
		}
		iframe {
			max-width: 811px;
			margin: 0 auto;
		}
		ul {
			border-top: 1px dashed #fff;
			max-width: 811px;
			margin: 0 auto;
			li {
				display: flex;
				align-items: center;
				font-size: 15px;
				color: white;
				line-height: 1.5;
				border-bottom: 1px dashed #fff;
				padding: 10px 25px;
				@include sp() {
					display: block;
					padding: 10px;
				}
				.date {
					margin-right: 60px;
					@include sp() {
						display: block;
						margin-bottom: 5px;
					}
				}
				.text {
				}
			}
		}
	}
}
