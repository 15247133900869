#about {
	.sec01 {
		padding: 55px 0 110px;
		@include sp() {
			padding: 40px 0;
		}
		&__title {
			text-align: center;
		}
		&__kazari {
			text-align: right;
			margin: -50px auto 0;
			max-width: 994px;
			@include sp() {
				margin: 10px 0 0;
				img {
					width: 100px;
				}
			}
		}
	}
	.sec01-slider {
		max-width: 994px;
		margin: 0 auto;
		div {
			line-height: 1;
			vertical-align: bottom;
		}
		.slick-slider {
			margin-bottom: 0;
		}
		.slick-list {
			overflow: hidden;
			img {
				width: 100%;
			}
		}
		.slick-slide {
			@include sp() {
				padding: 0;
			}
			a {
				text-decoration: none;
			}
			img {
				border-radius: 0 32px 0 32px;
			}
			p {
				font-size: 21px;
				margin-top: 10px;
				line-height: 1.5;
				text-align: center;
			}
		}
	}
	.sec01-slider-nav {
		max-width: 994px;
		margin: 10px auto 0;
		.slick-slide {
			padding: 0 5px;
			opacity: .6;
			&.slick-current {
				opacity: 1;
			}
		}
		.slick-arrow {
			cursor: pointer;
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto 0;
			width: 17px;
			@include sp() {
				width: 10px;
			}
			&.prev {
				left: -50px;
				transform: rotate(180deg);
				@include sp() {
					left: -15px;
				}
			}
			&.next {
				right: -50px;
				@include sp() {
					right: -15px;
				}
			}
			&:hover {
				polygon {
					fill: $blue;
				}
			}
		}
		.slick-dots{
			position: absolute;
			bottom: -40px;
			left: 0;
			right: 0;
			width: 100%;
			text-align: center;
			@include sp() {
				bottom: -20px;
				left: 0;
				right: 0;
				display: flex;
				justify-content: center;
			}
			li {
				width: auto;
				height: auto;
				display: none;
				&.slick-active {
					display: inline-block;
				}
				button {
					width: auto;
					height: auto;
					padding: 0;
					color: #333;
					font-size: 24px;
					@include sp() {
						font-size: 16px;
					}
					&:before {
						content: none;
					}
					&:after {
						content: "/ 10";
						display: inline-block;
						margin-left: 5px;
					}
				}
			}
		}
	}
}
