// min-width
$breakpoint-up: (
	"sm": "screen and (min-width: 400px)",
	"md": "screen and (min-width: 768px)",
	"lg": "screen and (min-width: 1000px)",
	"xl": "screen and (min-width: 1200px)"
) !default;

// max-width
$breakpoint-down: (
	"sm": "screen and (max-width: 399px)",
	"md": "screen and (max-width: 767px)",
	"lg": "screen and (max-width: 999px)",
	"xl": "screen and (max-width: 1199px)"
) !default;

// @mixinの定義
@mixin tb-up($breakpoint: lg) {
	@media #{map-get($breakpoint-up, $breakpoint)} {
		@content;
	}
}
@mixin tb($breakpoint: lg) {
	@media #{map-get($breakpoint-down, $breakpoint)} {
		@content;
	}
}
@mixin sp-up($breakpoint: md) {
	@media #{map-get($breakpoint-up, $breakpoint)} {
		@content;
	}
}
@mixin sp($breakpoint: md) {
	@media #{map-get($breakpoint-down, $breakpoint)} {
		@content;
	}
}

// 13__ie11only
// ----------------------------------------------------------
@mixin ie11only {
	@media all and (-ms-high-contrast: none), print {
		@content;
	}
}

@mixin webfont {
	font-family: "icomoon" !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

$blue: #20aee5;