.l-header {
	position: absolute;
	top: 40px;
	left: 0;
	right: 0;
	z-index: 9;
	width: 1160px;
	margin: 0 auto;
	@include sp() {
		position: static;
		width: auto;
		padding-top: 20px;
	}
	&__inner {
		width: 320px;
		text-align: center;
		@include sp() {
			width: 100%;
		}
		.logo {
			margin-bottom: 10px;
			display: inline-block;
		}
	}

	@include sp() {
		&__inner {
		}
	}
}

.hdr-cv {
	margin-bottom: 10px;
	&__addr {
		margin-bottom: 10px;
	}
	&__tel {
		display: inline-block;
		margin-bottom: 10px;
	}
	&__time {
		letter-spacing: 0;
	}
}

.l-nav {
	&__list {
		display: flex;
		list-style: none;
	}
	&__item {
		width: 25%;
		&:last-child {
			border-right: 1px solid #9fa0a0;
		}
	}
	&__link {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		height: 100%;
		padding: 5px 0;
		border-left: 1px solid #9fa0a0;
		img {
		}
	}
}
.scroll {
	position: fixed;
	bottom: 20px;
	left: 20px;
	width: 22px;
	z-index: 99;
	cursor: pointer;
	@include sp() {
		//display: none;
	}
}